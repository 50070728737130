
export default [
  
    {
        path: '/activity/:group_id',
        name: 'activity',
        props:true,
        component: () => import('@/views/activity/ActivityMainPage.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"點名主頁",
            },
            toolbar:{
                title:'點名主頁',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/activity/list/:group_id',
        name: 'activity/list',
        props:true,
        component: () => import('@/components/activity/ActivityList.vue'),
        meta: { 
            requiresAuth: true ,
            permissions:['smartGroup.#.edit'],

            seo:{
                title:"點名單",
            },
            toolbar:{
                title:'點名單',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/activity/quickstart/:group_id',
        name: 'activity/quickstart',
        props:true,
        component: () => import('@/views/activity/ActivityQuickStartPage.vue'),
        meta: { 
            requiresAuth: true ,
            permissions:['smartGroup.#.edit'],

            seo:{
                title:"快速點名",
            },
            toolbar:{
                title:'快速點名',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/activity/start/:activity_id',
        name: 'activity/start',
        props:true,
        component: () => import('@/views/activity/ActivityStartingPage.vue'),
        meta: { 
            requiresAuth: true ,
            permissions:['smartGroup.#.edit'],

            seo:{
                title:"點名",
            },
            toolbar:{
                title:'點名',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/activity/attendee/:activity_id',
        name: 'activity/attendee',
        props:true,
        component: () => import('@/components/activity/ActivityAttendee.vue'),
        meta: { 
            requiresAuth: true ,
            permissions:['smartGroup.#.edit'],

            seo:{
                title:"出席名單",
            },
            toolbar:{
                title:'出席名單',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/activity/sign',
        name: 'activity/sign',
        props:true,
        component: () => import('@/views/activity/ActivitySignPage.vue'),
        meta: { 
            requiresAuth: true ,
            seo:{
                title:"簽到",
            },
            toolbar:{
                title:'簽到',
                showBack:true,
                styleReverse:true,
                backTo:{name:'group'}
            }
        },
    },
    {
        path: '/attend/list',
        name: 'attend/list',
        props:true,
        component: () => import('@/views/activity/MyAttendListPage.vue'),
        meta: { 
            requiresAuth: true ,
            permissions:[],

            seo:{
                title:"我的出席紀錄",
            },
            // toolbar:{
            //     title:'出席紀錄',
            //     showBack:true,
            //     styleReverse:true,
            //     backTo:{name:'group'}
            // }
        },
    },
];