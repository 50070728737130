import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router';
import AppLayout from '@/components/layouts/AppLayout.vue';

import routeEvent from './modules/routeEvent';
import routeLogin from './modules/routeLogin';
import routeAccount from './modules/routeAccount';
import routeOrganizer from './modules/routeOrganizer';
import routePayment from './modules/routePayment';
import routeTickets from './modules/routeTickets';
import routeDonates from './modules/routeDonates';
import routeActivity from './modules/routeActivity';
import routeGroup from './modules/routeGroup';

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          // return savedPosition
          return { left: 0, top: 0}
    
        } else {
          return {left: 0, top: 0 }
        }
      },
    routes: [
        ...routeLogin,
        {
            path: '/landing',
            name: 'landing',
            beforeEnter() {
                window.location.href = process.env.VUE_APP_LANDING
            }
            // component: () => import('@/views/LandingPage.vue'),
            // meta: { 
            //     requiresAuth: false  ,
            //     seo:{
            //         title:'首頁'
            //     }
            // },
        },
        {
            path: '/',
            redirect:'/landing',
            component: AppLayout,
            children: [
                {
                    path: '/home',
                    name: 'home',
                    redirect:'/ticket/list',
                    component: () => import('@/views/ticket/TicketListPage.vue'),
                    meta: { 
                        requiresAuth: true ,
                        seo:{
                            title:"首頁",
                        },
                    },
                     
                },
                {
                    path: '/explore',
                    name: 'explore',
                    component: () => import('@/views/explore/ExplorePage.vue'),
                    meta: { 
                        requiresAuth: false ,
                        seo:{
                            title:"探索",
                        },
                    },
                     
                },
                {
                    path: '/terms',
                    name: 'terms',
                    component: () => import('@/views/others/TermsPage.vue'),
                    meta: { 
                        requiresAuth: false ,
                    },
                },
                {
                    path: '/privacy',
                    name: 'privacy',
                    component: () => import('@/views/others/PrivacyPage.vue'),
                    meta: { 
                        requiresAuth: false ,
                    },
                },
                {
                    path: '/contact',
                    name: 'contact',
                    component: () => import('@/views/others/Contact.vue'),
                    meta: { 
                        requiresAuth: false ,
                    },
                },
                {
                    path: '/company/apply',
                    name: 'company/apply',
                    component: () => import('@/views/others/ApplyPage.vue'),
                    meta: { 
                        requiresAuth: true  ,
                        seo:{
                            title:"組織申請表"
                        }
                    },
                },
                {
                    path: '/company/apply/success',
                    name: 'company/apply/success',
                    component: () => import('@/views/others/ApplySuccessPage.vue'),
                    meta: { 
                        requiresAuth: true  ,
                        seo:{
                            title:"組織申請成功"
                        }
                    },
                },
                {
                    path: '/APIFailed',
                    name: 'APIFailed',
                    component: () => import('@/views/others/APIFailedPage.vue'),
                    meta: { 
                        requiresAuth: false ,
                    },
                },
                ...routeEvent,
                ...routeAccount,
                ...routeOrganizer,
                ...routePayment,
                ...routeTickets,
                ...routeDonates,
                ...routeActivity,
                ...routeGroup,
            ]
        },
        {
            path: '/404page',
            name: '404page',
            component: () => import('@/views/others/404Page.vue'),
            meta: { 
                requiresAuth: false ,
            },
        },
        {
            path: '/permissiondeny',
            name: 'permissiondeny',
            component: () => import('@/views/others/PermissionDenyPage.vue'),
            props:true,
            meta: { 
                requiresAuth: false ,
            },
        },
        {
           
            path: '/plandeny',
            name: 'plandeny',
            component: () => import('@/views/others/PlanDenyPage.vue'),
            props:true,
            meta: { 
                requiresAuth: false ,
            },
        },
        {
            path: '/:pathMatch(.*)*',
            component: () => import('@/views/others/404Page.vue'),
            meta: { 
                requiresAuth: false ,
            },
        },
    ]
});

import { useUserStore } from '@/stores/user'
import { useCompanyStore } from '@/stores/company'
import { useLayout } from '@/components/composables/layout'; 
const whiteList = []
function isInWhiteList(path){
    return  whiteList.indexOf(path)>=0;
}
// beforeResolve
router.beforeEach( (to, from,next) => {
    const store = useUserStore()
    const { asyncDone } = useLayout();
    const storeCompany = useCompanyStore()
    const _checkPermission= ()=>{
        if(to.meta.permissions && to.meta.permissions.length>0){
            return store.canAnyAction(to.meta.permissions)
        }else{
            return true
        }
    }
    if( !to.matched.some(record => record.meta.requiresAuth)){
        next()
    }else{
        store.getMyProfile().then((data)=>{
            if(data){
                if(store.user.is_verified==false){
                    next( { name: 'verify' })
                }else{     
                    if(!_checkPermission()){
                        next( { name: 'permissiondeny' })
                    }
                    if(to.meta.requireCompany === true){
                        storeCompany.getMyGroupCompanies().then((data)=>{
                            next()
                        })

                    }else{
                        next()
                    }
                }
                
            }else{
                next( { name: 'login' })
            }
        })
    }
   
});
 
export default router;
