import { createApp,markRaw } from 'vue'

import App from './App.vue'
import router from './router';
import vClickOutside from "click-outside-vue3"
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createGtm } from '@gtm-support/vue-gtm';
import { createHead, VueHeadMixin } from '@unhead/vue'
import uaParser from './plugins/user-agent-parser.js'

import VueIcon from '@jamescoyle/vue-icon'
import Ahref from './components/utils/Ahref.vue'
// import moment from 'moment'
import moment from 'moment-timezone'
import vue3GoogleLogin from 'vue3-google-login'
import vueDebounce from 'vue-debounce'

import './assets/theme/goodder-theme/theme.css'
import './assets/theme/custom.css'
import './assets/theme/local.css'

import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css'

import Primevue from 'primevue/config'
// import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
// import AvatarGroup from 'primevue/avatargroup';
// import Badge from 'primevue/badge';
// import BadgeDirective from 'primevue/badgedirective';
// import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
// import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
// import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
// import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
// import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
// import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
// import Dock from 'primevue/dock';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
// import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
// import InlineMessage from 'primevue/inlinemessage';
// import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
// import Knob from 'primevue/knob';
// import Listbox from 'primevue/listbox';
// import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
// import MultiSelect from 'primevue/multiselect';
// import OrderList from 'primevue/orderlist';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
// import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
// import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
// import Ripple from 'primevue/ripple';
// import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
// import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
// import SpeedDial from 'primevue/speeddial';
// import SplitButton from 'primevue/splitbutton';
// import Splitter from 'primevue/splitter';
// import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
// import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
// import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
// import Terminal from 'primevue/terminal';
// import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import InputOtp from 'primevue/inputotp';

// import TreeSelect from 'primevue/treeselect';
// import TreeTable from 'primevue/treetable';
// import TriStateCheckbox from 'primevue/tristatecheckbox';
// import VirtualScroller from 'primevue/virtualscroller';
import Editor from 'primevue/editor';


import { createI18n } from 'vue-i18n'
import language from './I18n/language'
const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: 'tw', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: language,
  })
const gtm_config = {
    id: process.env.VUE_APP_GTM_CONTAINER, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: process.env.VUE_APP_GTM_AUTH,
      gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
      gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN
    },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }
const app = createApp(App);
app.directive('debounce', vueDebounce({ lock: true }))

const pinia = createPinia()
moment.locale('zh-tw')
app.config.globalProperties.$moment = moment
app.config.globalProperties.$PRODUCT_NAME = process.env.VUE_APP_PRODUCT_NAME;
//user-agent
let userAgent = navigator.userAgent || navigator.vendor || window.opera;
let userAgentParsed = uaParser.parse(userAgent)
app.config.globalProperties.$ua = userAgentParsed

app.component('v-icon',VueIcon)

pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => { 
    store.router = markRaw(router) 
})
app.use(pinia)
app.use( createGtm(gtm_config))
const head = createHead()
app.use(head)
app.mixin(VueHeadMixin)

app.use(i18n)
app.use(Primevue);
app.use(router);
app.use(ConfirmationService);

app.use(vClickOutside)
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID  
})
app.use(ToastService);
app.component('Ahref',Ahref)
app.component('Button',Button)
app.component('InputText',InputText)
app.component('Image',Image)
app.component('Toast',Toast)
app.component('Galleria',Galleria)
app.component('Menu',Menu)

app.component('Menubar',Menubar)
app.component('Toolbar',Toolbar)
app.component('Card',Card)
app.component('Divider',Divider)
app.component('Message',Message)
app.component('Avatar',Avatar)
app.component('Panel',Panel)
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('InputNumber', InputNumber);
app.component('Steps', Steps);
app.component('Tree', Tree);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);


app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('FileUpload', FileUpload);
app.component('Textarea', Textarea);
app.component('Dropdown', Dropdown);
app.component('RadioButton', RadioButton);
app.component('Calendar', Calendar);
app.component('Editor', Editor);
app.component('ScrollPanel', ScrollPanel);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('Tag', Tag);
app.component('Checkbox', Checkbox);
app.component('ProgressBar', ProgressBar);
app.component('Skeleton', Skeleton);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Breadcrumb', Breadcrumb);
app.component('Rating', Rating);
app.component('Slider', Slider);
app.component('InputSwitch', InputSwitch);
app.directive('tooltip', Tooltip);
app.component('Sidebar', Sidebar);
app.component('ToggleButton', ToggleButton);
app.component('TabMenu', TabMenu);
const CustomCarousel = { extends: Carousel, props: { numVisible: Number, numScroll: Number, responsiveOptions: Array, }, methods: { onTouchMove() { return }, }, } 

app.component('Carousel',CustomCarousel)
app.component('SelectButton',SelectButton)
app.component('InputMask',InputMask)
app.component('DataView',DataView)
app.component('Paginator',Paginator)
app.component('Password', Password);
app.component('OverlayPanel', OverlayPanel);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DynamicDialog', DynamicDialog);
app.component('InputOtp', InputOtp);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ConfirmDialog', ConfirmDialog);

app.use( DialogService);

app.mount('#app');
